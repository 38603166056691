const app = {
  init: function () {
    this.initNav();
    this.initSearchToggle();
    this.initVideos();
  },

  initNav: function () {
    const htmlEl = document.querySelector("html");
    const mobileNavBtns = Array.from(
      document.querySelectorAll(".btn-mobile-nav")
    );

    if (mobileNavBtns) {
      mobileNavBtns.map((btn) => {
        btn.addEventListener("click", () => {
          if (htmlEl) {
            htmlEl.classList.toggle("nav-is-open");
          }
        });
      });
    }
  },

  initSearchToggle: function () {
    const htmlEl = document.querySelector("html");
    const searchBtns = Array.from(document.querySelectorAll(".btn-search"));

    if (searchBtns) {
      searchBtns.map((btn) => {
        btn.addEventListener("click", () => {
          if (htmlEl) {
            htmlEl.classList.toggle("search-is-open");
          }
        });
      });
    }
  },

  initVideos: function () {
    fluidvids.init({
      selector: ["iframe", "object"], // runs querySelectorAll()
      players: ["www.youtube.com", "player.vimeo.com"], // players to support
    });

    // look for wp block cover with video
    const heroContainer = document.querySelector(".wp-block-cover");
    const heroVideo = document.querySelector(
      ".wp-block-cover__video-background"
    );

    // hero autoplay check
    if (heroVideo) {
      heroVideo.pause();
      heroVideo.play();

      // check if phone is in power saving mode
      heroVideo.addEventListener("suspend", () => {
        heroVideo.play();

        if (heroVideo.paused) {
          heroVideo.classList.add("video-disabled");

          if (heroContainer) {
            heroContainer.classList.add("container-video-disabled");
          }
        }
      });
    }
  },
};

app.init();
